<template>
  <section class="to_execute">
    
      
     
      
  
         
    <div class="row">
        
 

            <!-- COL1 -->   
             <div class="col-md-12">
                 
                 
                 
                 
               <div class="row"  >   
                     
                 <div class="col-12 grid-margin mb-2">
                   <div class="card">
                     <div class="faq-block card-body">
                         
                         
                           <div v-show="rldata.is_loaded_data" >
                         
                         
                                    <div v-if="rldata.flagserror_action.is_error_ticknum_not_find">
                                        <h4 class="mt-2">Ошибка!</h4>
                                        <p class="mt-2">{{ rldata.flagserror_action.text_error_ticknum_not_find }} ( ticknum={{ rldata['ticknum'] }} )</p>
                                    </div>
                                    <div v-else>
                                    
                                       <h4 class="mt-2">Исполнено!</h4>
                                       <p class="mt-2">Заявка {{ rldata['ticknum'] }} переведена в исполнение </p>
                                    </div>
                          </div>
                         
                                     <p v-if="rldata.flagserror_common.is_error_in_sending_data" class="text-danger">{{ this.$root.error_text.text_error_in_sending_data }}</p>
                                     <p v-if="rldata.flagserror_common.is_error_execution_api" class="text-danger" >{{ this.$root.error_text.text_error_execution_api }}</p>
                        
                         
                         
                         
                     </div> 
                   </div> 
                 </div> 
                </div> 
                 
            
                 
                  



















               </div>
             
                 
                 
        
                         
                         
                         
                         
  
           </div>  
         </div>  
        




 
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
       
  
  
  
  
  </section>
</template>

<script>
import $ from 'jquery'
import axios from 'axios';
import axiosRetry from 'axios-retry';   


var data = {
   
   is_loaded_data : false,
    
    ticknum : '',
   
    flags_logic: {
        //is_act_disabled : false,
      
    },    
    flagserror_common: {
            is_error_in_sending_data: false,
            is_error_execution_api: false,
    },    
    flagserror_action: {  //   rldata.flagserror_action.is_error_ticknum_not_find   rldata.flagserror_action.text_error_ticknum_not_find
             is_error_ticknum_not_find: false,
             text_error_ticknum_not_find: "Тикет не найден. ",
    },
    
    
  
}      
  
 
  
export default {
  name: 'to_execute',  
components: {
   
},

  
    data () { //тюненгуемые переменные и функции
        return {
            rldata: data,  
        };
    },
  
  
  mounted () {
   
   // this.$root.check_version(this.rldata, true);
   
   this.rldata['ticknum']="";
  
       
         //получить данные о пользователе из ls
    var url = new URL(window.location.href);    
    this.rldata['ticknum']=url.searchParams.get("ticknum"); 
    
    
     this.rldata['is_loaded_data']=false;
     this.rldata['flagserror_action']['is_error_ticknum_not_find']=false;
     this.rldata['flagserror_common']['is_error_in_sending_data']=false;
     this.rldata['flagserror_common']['is_error_in_sending_data']=false;
     
     
     
   
     this.$root.to_execute_form_action(this.rldata); //a_qrcodes
   // this.rldata['is_loaded_data']=false; 
    
  },  
  
  
  
  
  
  
methods: {
      
  
},
  
  
 
   computed: { 
            
                        
            },
  
}
  
  
  

</script>


<style scoped>
    .accordion .card .card-header a{
        padding-right: 1.5rem;
    }
    
    .btn_custom_width {
        width: 120px !important;
    }
   
    
    input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}  
  
</style>